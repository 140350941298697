// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.socialsContainer {
  z-index: 2;
  position: absolute;
  top: 10px;
  left: 70px;
  vertical-align: bottom;
  margin: 5px 10px;
  font-size: 15px;
  font-weight: 300;
  letter-spacing: 1px;
  vertical-align: top;
}
.socialsContainer .link {
  display: inline-block;
  vertical-align: top;
  color: rgba(255, 255, 255, 0.95);
  font-weight: 300;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 3px;
  transition: color 0.3s;
}
.socialsContainer .link:hover {
  text-decoration: none;
  opacity: 0.5;
}
.socialsContainer .link .instaLogo {
  width: 12px;
}
.socialsContainer .link .fetlifeLogo {
  width: 12px;
}
.socialsContainer .link .emailLogo {
  width: 14px;
}
.socialsContainer .spacer {
  display: inline-block;
  color: rgba(255, 255, 255, 0.25);
}
@media (max-width: 680px) {
  .socialsContainer {
    margin: 0;
    top: 140px;
    right: 0z;
    left: 0;
    width: 100vw;
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Socials/Socials.less"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,kBAAA;EACA,SAAA;EACA,UAAA;EACA,sBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;AACJ;AAXA;EAaQ,qBAAA;EACA,mBAAA;EACA,gCAAA;EACA,gBAAA;EACA,eAAA;EACA,qBAAA;EACA,mBAAA;EACA,sBAAA;AACR;AAAQ;EACI,qBAAA;EACA,YAAA;AAEZ;AAzBA;EA0BY,WAAA;AAEZ;AA5BA;EA6BY,WAAA;AAEZ;AA/BA;EAgCY,WAAA;AAEZ;AAlCA;EAqCQ,qBAAA;EACA,gCAAA;AAAR;AAIA;EACI;IACI,SAAA;IACA,UAAA;IACA,SAAA;IACA,OAAA;IACA,YAAA;IACA,kBAAA;EAFN;AACF","sourcesContent":[".socialsContainer {\n    z-index: 2;\n    position: absolute;\n    top: 10px;\n    left: 70px;\n    vertical-align: bottom;\n    margin: 5px 10px;\n    font-size: 15px;\n    font-weight: 300;\n    letter-spacing: 1px;\n    vertical-align: top;\n\n    .link {\n        display: inline-block;\n        vertical-align: top;\n        color: rgba(255, 255, 255, .95);\n        font-weight: 300;\n        cursor: pointer;\n        text-decoration: none;\n        letter-spacing: 3px;\n        transition: color .3s;\n        &:hover {\n            text-decoration: none;\n            opacity: .5;\n        }\n        .instaLogo {\n            width: 12px;\n        }\n        .fetlifeLogo {\n            width: 12px;\n        }\n        .emailLogo {\n            width: 14px;\n        }\n    }\n\n    .spacer {\n        display: inline-block;\n        color: rgba(255, 255, 255, .25);\n    }\n}\n\n@media (max-width: 680px) {\n    .socialsContainer { \n        margin: 0;   \n        top: 140px;\n        right: 0z;\n        left: 0;\n        width: 100vw;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
