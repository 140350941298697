// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.showSensibleContainer {
  z-index: 2;
  position: absolute;
  top: 0;
  right: 0;
  vertical-align: bottom;
  margin: 5px 10px;
}
.showSensibleSwitch {
  background-color: rgba(255, 255, 255, 0.5);
}
.showSensibleText {
  position: relative;
  top: -3px;
  display: inline-flex;
  font-size: 12px;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 300;
  letter-spacing: 1px;
  opacity: 0.35;
}
`, "",{"version":3,"sources":["webpack://./src/components/ShowSensibleSlider/ShowSensibleSlider.less"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,kBAAA;EACA,MAAA;EACA,QAAA;EACA,sBAAA;EACA,gBAAA;AACJ;AAEA;EACI,0CAAA;AAAJ;AAGA;EACI,kBAAA;EACA,SAAA;EACA,oBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,aAAA;AADJ","sourcesContent":[".showSensibleContainer {\n    z-index: 2;\n    position: absolute;\n    top: 0;\n    right: 0;\n    vertical-align: bottom;\n    margin: 5px 10px;\n}\n\n.showSensibleSwitch {\n    background-color: rgba(255, 255, 255, .5);\n}\n\n.showSensibleText {\n    position: relative;\n    top: -3px;\n    display: inline-flex;\n    font-size: 12px;\n    padding-left: 5px;\n    padding-right: 5px;\n    font-weight: 300;\n    letter-spacing: 1px;\n    opacity: .35;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
