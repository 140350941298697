// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./KleistFrakturZier.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'KleistFrakturZier';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}
.mainTitleDesc {
  position: relative;
  top: -10px;
  left: 5px;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 9px;
  color: white;
  opacity: 0.5;
}
.mainTitleFraktur {
  font-family: 'KleistFrakturZier';
  font-size: 52px;
  color: white;
  padding-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/Title/Title.less"],"names":[],"mappings":"AAAA;EACE,gCAAA;EACA,+DAAA;AACF;AAEA;EACE,kBAAA;EACA,UAAA;EACA,SAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,YAAA;EACA,YAAA;AAAF;AAGA;EACE,gCAAA;EACA,eAAA;EACA,YAAA;EACA,oBAAA;AADF","sourcesContent":["@font-face {\n  font-family: 'KleistFrakturZier';\n  src: url('./KleistFrakturZier.ttf') format('truetype');\n}  \n\n.mainTitleDesc {\n  position: relative;\n  top: -10px;\n  left: 5px;\n  font-size: 13px;\n  font-weight: 300;\n  letter-spacing: 9px;\n  color: white;\n  opacity: .5;\n}\n\n.mainTitleFraktur {\n  font-family: 'KleistFrakturZier';\n  font-size: 52px;\n  color: white;\n  padding-bottom: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
