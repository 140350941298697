// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Helvetica Neue";
  margin: 0rem;
  overflow-x: hidden;
  height: 100vh;
  width: 100vw;
}
.App {
  font-size: 25px;
  color: white;
  /* Fallback for browsers that do not support Custom Properties */
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
}
.link {
  color: rgba(255, 255, 255, 0.95);
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s;
}
.link:hover {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
.pointer:hover {
  font-weight: 300;
}
/* Tooltip custom */
.ant-tooltip {
  color: rgba(255, 255, 255, 0.75);
}
.ant-tooltip .ant-tooltip-inner {
  color: #000;
  background-color: rgba(255, 255, 255, 0.85);
}
.ant-tooltip .ant-tooltip-arrow:after {
  background-color: rgba(255, 255, 255, 0.8);
}
`, "",{"version":3,"sources":["webpack://./src/App.less"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,6BAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;AACF;AAEA;EACE,eAAA;EACA,YAAA;EAAA,gEAAgE;EAEhE,iBAAA;EACA,sCAAA;AAAF;AAGA;EACE,gCAAA;EACA,eAAA;EACA,qBAAA;EACA,sBAAA;AADF;AAIA;EACE,gCAAA;EACA,0BAAA;AAFF;AAKA;EACE,eAAA;AAHF;AAMA;EACE,gBAAA;AAJF;AACA,mBAAmB;AAOnB;EACE,gCAAA;AALF;AAIA;EAGI,WAAA;EACA,2CAAA;AAJJ;AAOI;EACE,0CAAA;AALN","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Helvetica Neue\";\n  margin: 0rem;\n  overflow-x: hidden;\n  height: 100vh;\n  width: 100vw;\n}\n\n.App {\n  font-size: min(calc(7px + 2vmin), 25px);\n  color: white;\n  /* Fallback for browsers that do not support Custom Properties */\n  min-height: 100vh;\n  min-height: calc(var(--vh, 1vh) * 100);\n}\n\n.link {\n  color: rgba(255, 255, 255, .95);\n  cursor: pointer;\n  text-decoration: none;\n  transition: color .3s;\n}\n\n.link:hover {\n  color: rgba(255, 255, 255, .75);\n  text-decoration: underline;\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.pointer:hover {\n  font-weight: 300;\n}\n\n/* Tooltip custom */\n.ant-tooltip {\n  color: rgba(255, 255, 255, 0.75);\n  .ant-tooltip-inner {\n    color: #000;\n    background-color: rgba(255, 255, 255, 0.85);\n  }\n  .ant-tooltip-arrow {\n    &:after {\n      background-color: rgba(255, 255, 255, 0.8);\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
