// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.languageDropdown {
  position: absolute;
  z-index: 10;
  top: 5px;
  left: 0;
  color: #ffffff;
  margin: 10px 10px;
  font-size: 14px;
  letter-spacing: 3px;
  font-weight: 300;
  cursor: pointer;
}
.languageDropdown .anticon-down {
  color: rgba(255, 255, 255, 0.5);
}
.ant-dropdown .ant-dropdown-menu {
  border-radius: 4px;
  margin-top: 3px;
  background-color: rgba(255, 255, 255, 0.9);
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  font-size: 14px;
  font-weight: 400;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
  font-weight: 500;
}
`, "",{"version":3,"sources":["webpack://./src/components/LanguageDropDown/LanguageDropDown.less"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,WAAA;EACA,QAAA;EACA,OAAA;EACA,cAAA;EACA,iBAAA;EAEA,eAAA;EACA,mBAAA;EACA,gBAAA;EACA,eAAA;AAAF;AAXA;EAcI,+BAAA;AAAJ;AAIA;EAEI,kBAAA;EACA,eAAA;EACA,0CAAA;AAHJ;AADA;EAMM,eAAA;EACA,gBAAA;AAFN;AAGM;EACE,gBAAA;AADR","sourcesContent":[".languageDropdown {\n  position: absolute;\n  z-index: 10;\n  top: 5px;\n  left: 0;\n  color: rgba(255,255,255,1);\n  margin: 10px 10px;\n  font-weight: 300;\n  font-size: 14px;\n  letter-spacing: 3px;\n  font-weight: 300;\n  cursor: pointer;\n  \n  .anticon-down {\n    color: rgba(255,255,255, .5);\n  }\n}\n\n.ant-dropdown {\n  .ant-dropdown-menu {\n    border-radius: 4px;\n    margin-top: 3px;\n    background-color: rgba(255,255,255, .9);\n    .ant-dropdown-menu-item {\n      font-size: 14px;\n      font-weight: 400;\n      &:hover {\n        font-weight: 500;\n      }\n    }\n  }\n}\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
